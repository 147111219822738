import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAppState } from '../../app/contexts/AppStateContext';
import { listenIsScrollableHorizontally } from '../../lib/helpers/scroll';
import { sortCompareFunctionWithUndefined } from '../../lib/helpers/sort';
import { BASE_SIZE } from '../../styles/yr/variables/_variables';
import { ScrollShadow } from '../ScrollShadow/ScrollShadow';
import './FluidTable.scss';
import { FluidTable__Caption } from './FluidTable__Caption';
import { FluidTable__Cell } from './FluidTable__Cell';
import { FluidTable__Heading } from './FluidTable__Heading';

export interface IProps {
  tableCaption: string;
  stickyFirstColumn?: boolean;
  columns: IFluidTableColumn[];
  rows: IFluidTableRow[];
}

export interface IFluidTableColumn {
  testId?: string;
  visible: boolean;
  align?: 'left' | 'center' | 'right';
  className?: string;
  heading: string | JSX.Element;
  sortable?: {
    isDefault?: boolean;
    getSortValue: (index: number) => string | number | undefined;
    getSecondarySortValue?: (index: number) => string | number | undefined;
  };
}

export interface IFluidTableRow {
  href?: string;
  cells: TFluidTableCell[];
}

export type TFluidTableCell = string | number | JSX.Element;

export function FluidTable(props: IProps) {
  const { tableCaption, stickyFirstColumn = false, columns, rows } = props;

  const [isScrollable, setIsScrollable] = useState({
    left: false,
    right: false,
    offsetLeft: 0
  });

  const defaultSortableColumnIndex = getDefaultSortableColumnIndex(columns);
  const isSortable = defaultSortableColumnIndex != null;

  const { history } = useAppState();
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const tableHeadRef = useRef<HTMLTableSectionElement>(null);
  const [sort, setSort] = useState<{ columnIndex: number; direction: 'ascending' | 'descending' } | undefined>(
    defaultSortableColumnIndex != null
      ? {
          columnIndex: defaultSortableColumnIndex,
          direction: 'ascending'
        }
      : undefined
  );

  useEffect(() => {
    const tableScroller = scrollContainerRef.current;
    const tableHeadElement = tableHeadRef.current;

    if (tableScroller == null || tableHeadElement == null) {
      return;
    }

    const cancel = listenIsScrollableHorizontally(tableScroller, newIsScrollable => {
      const stickyColumnCell = tableHeadElement.querySelector('th');
      if (stickyColumnCell == null) {
        return;
      }

      // In .fluid-table we set a padding-left of size(2) IF the first column is sticky AND the table is scrollable.
      // In order to get the corrrect position for the scroll shadow we need to add this padding to the cell width whenever the table is scrollable.
      const shadowOffsetLeft = Math.round(stickyColumnCell.getBoundingClientRect().width) + BASE_SIZE * 2;

      setIsScrollable({ ...newIsScrollable, offsetLeft: shadowOffsetLeft });
    });

    return cancel;
  }, [scrollContainerRef]);

  const sortedRowIndexes = useMemo(() => {
    const rowIndexes = rows.map((_, index) => index);
    if (sort == null) {
      return rowIndexes;
    }

    const sortable = columns[sort.columnIndex].sortable;
    if (sortable == null) {
      return rowIndexes;
    }

    rowIndexes.sort((indexA, indexB) => {
      const a = sortable.getSortValue(sort.direction === 'ascending' ? indexA : indexB);
      const b = sortable.getSortValue(sort.direction === 'ascending' ? indexB : indexA);
      const primarySortResult = sortCompareFunctionWithUndefined(a, b);

      // If "a" and "b" is identical and we have a secondary sort value
      // we sort by the secondary sort value in ascending direction.
      if (primarySortResult === 0 && sortable.getSecondarySortValue != null) {
        const secondaryA = sortable.getSecondarySortValue(indexA);
        const secondaryB = sortable.getSecondarySortValue(indexB);

        return sortCompareFunctionWithUndefined(secondaryA, secondaryB);
      }

      return primarySortResult;
    });

    return rowIndexes;
  }, [columns, rows, sort]);

  const resort = useCallback(
    (columnIndex?: number) => {
      if (columnIndex == null) {
        setSort(undefined);
        return;
      }

      if (sort?.columnIndex === columnIndex) {
        setSort({
          columnIndex,
          direction: sort.direction === 'ascending' ? 'descending' : 'ascending'
        });

        return;
      }

      setSort({ columnIndex, direction: 'ascending' });
    },
    [sort]
  );

  return (
    <div
      className="fluid-table"
      data-is-scrollable={isScrollable.left || isScrollable.right}
      data-sticky-first-column={stickyFirstColumn}
    >
      <div
        className="fluid-table__scroll-shadow-left"
        style={{ left: stickyFirstColumn ? isScrollable.offsetLeft : 0 }}
      >
        <ScrollShadow position="left" visible={isScrollable.left} />
      </div>

      <div className="fluid-table__scroll-shadow-right">
        <ScrollShadow position="right" visible={isScrollable.right} />
      </div>

      <div className="fluid-table__scroll-container" ref={scrollContainerRef}>
        <table className="fluid-table__table">
          <FluidTable__Caption tableCaption={tableCaption} columns={columns} isSortable={isSortable} sort={sort} />

          <thead ref={tableHeadRef}>
            <tr>
              {columns.map((column, columnIndex) => {
                if (column.visible === false) {
                  return null;
                }

                const sticky = stickyFirstColumn ? columnIndex === 0 : false;

                let sortDirection: 'none' | 'ascending' | 'descending' | undefined = undefined;
                if (column.sortable != null) {
                  if (sort?.columnIndex === columnIndex) {
                    sortDirection = sort.direction;
                  } else {
                    sortDirection = 'none';
                  }
                }

                return (
                  <FluidTable__Heading
                    key={columnIndex}
                    testId={column.testId}
                    className={column.className}
                    align={column.align}
                    sticky={sticky}
                    sortDirection={sortDirection}
                    onSort={
                      column.sortable != null
                        ? () => {
                            resort(columnIndex);
                          }
                        : undefined
                    }
                  >
                    {column.heading}
                  </FluidTable__Heading>
                );
              })}
            </tr>
          </thead>

          <tbody>
            {sortedRowIndexes.map(rowIndex => {
              const row = rows[rowIndex];

              return (
                <tr
                  key={rowIndex}
                  className={classNames('fluid-table__row', { 'fluid-table__row--link': row.href != null })}
                  onClick={(event: React.MouseEvent) => {
                    // If the doesn't have a URL to navigate to we shouldn't do anything
                    if (row.href == null) {
                      return;
                    }

                    // Ignore clicks on actual links within this row.
                    // This makes it possible to command click the link
                    // to open it in a background tab for instance.
                    if (event.target instanceof HTMLAnchorElement) {
                      return;
                    }

                    history.push(row.href);
                  }}
                >
                  {row.cells.map((cell, cellIndex) => {
                    const column = columns[cellIndex];
                    const sticky = stickyFirstColumn ? cellIndex === 0 : false;

                    if (column.visible) {
                      return (
                        <FluidTable__Cell
                          key={cellIndex}
                          className={column.className}
                          align={column.align}
                          sticky={sticky}
                          href={cellIndex === 0 ? row.href : undefined}
                        >
                          {cell}
                        </FluidTable__Cell>
                      );
                    }
                    return null;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function getDefaultSortableColumnIndex(columns: IFluidTableColumn[]) {
  const firstDefaultSortableColumnIndex = columns.findIndex(column => column.sortable?.isDefault === true);
  if (firstDefaultSortableColumnIndex !== -1) {
    return firstDefaultSortableColumnIndex;
  }

  const firstSortableColumnIndex = columns.findIndex(column => column.sortable != null);
  if (firstSortableColumnIndex !== -1) {
    return firstSortableColumnIndex;
  }

  return undefined;
}
