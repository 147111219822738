import { Text } from '../Text/Text';
import { Timestamp } from '../Timestamp/Timestamp';
import { Translate } from '../Translate/Translate';

interface IProps {
  created: string;
}

export const LastUpdated = (props: IProps) => {
  const { created } = props;

  return (
    <Text size="5" color="secondary">
      <Translate
        id="grammar/timestampCreated"
        values={{
          time: <Timestamp timestamp={created} />
        }}
      />
    </Text>
  );
};
