import { useState } from 'react';
import { IPosition } from '../../model/position';
import { createDistanceFromLabel } from '../../lib/helpers/distance';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { InfoButton } from '../InfoButton/InfoButton';
import { InfoPanel } from '../InfoPanel/InfoPanel';
import { Translate } from '../Translate/Translate';
import './CoastHeader.scss';
import { Heading } from '../Heading/Heading';
import { Text } from '../Text/Text';

interface IProps {
  distanceFromLocation?: number;
  locationName: string;
  coastalPoint?: IPosition;
  children?: JSX.Element;
}

export const CoastHeader = (props: IProps) => {
  const { coastalPoint, distanceFromLocation, locationName, children } = props;
  const [showInfoPanel, setShowInfoPanel] = useState(false);
  const translate = useTranslate();
  const localeCode = useLocaleCode();

  const distanceFromLabel =
    distanceFromLocation != null
      ? createDistanceFromLabel({
          meters: distanceFromLocation,
          from: locationName,
          translate
        })
      : '';

  const coastHeaderInfoButtonId = 'coast-header__info-button';

  return (
    <div className="coast-header">
      <div className="coast-header__title-container">
        <div className="coast-header__title">
          <Heading level="h2" size="2">
            {translate('coastHeader/title')}
          </Heading>

          <InfoButton
            className="coast-header__title-icon"
            id={coastHeaderInfoButtonId}
            onClick={() => setShowInfoPanel(true)}
          />
          {showInfoPanel && (
            <InfoPanel
              openedById={coastHeaderInfoButtonId}
              title={translate('coastHeader/infoPanel/title')}
              subtitle={coastalPoint && `${coastalPoint.lat}, ${coastalPoint.lon}`}
              onClose={() => setShowInfoPanel(false)}
              testId="coast-header-info-panel"
              headerImage={{
                src: `/assets/images/coast/info/${localeCode}-832x330.jpg`,
                width: 832,
                height: 330,
                alt: translate('coastHeader/infoPanel/imageText')
              }}
              size="medium"
            >
              <p>{translate('coastHeader/infoPanel/body')}</p>
            </InfoPanel>
          )}
        </div>
        <Text size="5" className="coast-header__subtitle" data-testid="coast-header-subtitle">
          <Translate id="coastHeader/subtitle" values={{ distanceFromLabel }} />
        </Text>
      </div>
      <div className="coast-header__button-group">{children}</div>
    </div>
  );
};
