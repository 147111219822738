import loadable from '@loadable/component';
import { useMemo } from 'react';
import { useAppState } from '../../app/contexts/AppStateContext';
import { createPageUrl } from '../../app/redirects';
import { ButtonGroup, IGroupedLinkProps } from '../../components/ButtonGroup/ButtonGroup';
import { CardList } from '../../components/CardList/CardList';
import { CoastForecastDailyList } from '../../components/CoastForecastDailyList/CoastForecastDailyList';
import { CoastForecastHourlyDialog } from '../../components/CoastForecastHourlyDialog/CoastForecastHourlyDialog';
import { CoastGraph } from '../../components/CoastGraph/CoastGraph';
import { CoastHeader } from '../../components/CoastHeader/CoastHeader';
import { CoastTable } from '../../components/CoastTable/CoastTable';
import { ComponentErrorBoundary } from '../../components/ComponentErrorBoundary/ComponentErrorBoundary';
import { EmptyState } from '../../components/EmptyState/EmptyState';
import { ExternalServices } from '../../components/ExternalServices/ExternalServices';
import { Heading } from '../../components/Heading/Heading';
import { LastUpdated } from '../../components/LastUpdated/LastUpdated';
import { Loader } from '../../components/Loader/Loader';
import { PageRoot } from '../../components/PageRoot/PageRoot';
import { Text } from '../../components/Text/Text';
import { TideCard } from '../../components/TideCard/TideCard';
import { useFetchCoastForecastByLocationId } from '../../data/coast/hooks';
import { useFetchLocation } from '../../data/locations/hooks';
import { useFetchTideByLocationId } from '../../data/tide/hooks';
import { useFetchWarningsByLocationId } from '../../data/warnings/hooks';
import { useFetchWaterLevelByLocationId } from '../../data/waterLevel/hooks';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { COAST_WEATHER_WARNINGS, ICoastForecast } from '../../model/coast';
import { LocaleCode } from '../../model/locale';
import { ILocation } from '../../model/location';
import { getLocationIdFromParams } from '../../model/page';
import { ITranslateFunction } from '../../model/translate';
import { IWarning } from '../../model/warning';
import { filterWarningsByEventTypes, mapWarningsToIntervals } from '../../modelHelpers/warning';
import './CoastPage.scss';
import { createCoastIntervalsWithWaterLevel } from './helpers/data';

const ObservationsCards = loadable(() => import('../../components/ObservationsCards/ObservationsCards'));

function CoastPage() {
  const translate = useTranslate();
  const localeCode = useLocaleCode();

  const { currentPage, isFirstRender } = useAppState();
  const { subpageId } = currentPage.details.params;
  const { i: index } = currentPage.details.query;
  const locationId = getLocationIdFromParams(currentPage.details.params);

  const { data: location } = useFetchLocation({ locationId });
  const { data: tide, isError: tideIsError, isInitialLoading: tideIsInitialLoading } = useFetchTideByLocationId({
    locationId
  });
  const {
    data: coastForecast,
    isInitialLoading: coastForecastIsInitialLoading,
    isError: coastForecastIsError
  } = useFetchCoastForecastByLocationId({ locationId });
  const { data: waterLevel } = useFetchWaterLevelByLocationId({ locationId });
  const { data: warningList } = useFetchWarningsByLocationId({ locationId });

  const coastForecastWithWaterLevel = createCoastIntervalsWithWaterLevel({ coastForecast, waterLevel });

  const coastForecastWarnings = useMemo(() => {
    if (warningList == null) {
      return [];
    }

    return getFilteredWarningsGroupedByCoastForecastDayIntervals({ coastForecast, warnings: warningList.warnings });
  }, [coastForecast, warningList]);

  if (location == null || subpageId == null) {
    return null;
  }

  const buttons: IGroupedLinkProps[] = getCoastButtons(localeCode, translate, location);

  const currentTab = getCurrentCoastTabFromSubpageId(subpageId, buttons);

  function renderChildren() {
    if (location == null) {
      return null;
    }

    return (
      <div className="coast-page">
        <>
          <div className="layout-container layout-container--with-padding">
            <CoastHeader
              locationName={location.name}
              distanceFromLocation={coastForecast?.distanceFromLocation}
              coastalPoint={location.coastalPoint}
            >
              <ButtonGroup buttons={buttons} activeButtonId={currentTab.id} />
            </CoastHeader>
          </div>

          {coastForecastIsInitialLoading && <Loader type="block" />}
          {coastForecastIsError && (
            <div className="layout-container layout-container--with-padding">
              <EmptyState title={translate('coastPage/error/noData/title')}>
                <p>{translate('coastPage/error/noData/description')}</p>
              </EmptyState>
            </div>
          )}
          <div className="coast-page__tab-content">
            {coastForecastWithWaterLevel != null && (
              <>
                <div className="coast-page__forecast" id="forecast" hidden={currentTab.id !== 'forecast'}>
                  {(subpageId === 'forecast' || subpageId === 'hourly-table') && (
                    <div className="layout-container layout-container--with-padding">
                      <CoastForecastDailyList
                        coastForecast={coastForecastWithWaterLevel}
                        location={location}
                        warnings={coastForecastWarnings}
                      />
                    </div>
                  )}
                </div>

                <div className="coast-page__detailed-table" id="table" hidden={currentTab.id !== 'table'}>
                  {subpageId === 'table' && (
                    <div className="layout-container layout-container--with-padding">
                      <CoastTable coastForecast={coastForecastWithWaterLevel} />
                    </div>
                  )}
                </div>

                <div className="coast-page__graph" id="graph" hidden={currentTab.id !== 'graph'}>
                  {subpageId === 'graph' && (
                    <div className="layout-container layout-container--with-padding">
                      <>
                        {coastForecastWithWaterLevel != null && (
                          <CoastGraph coastForecast={coastForecastWithWaterLevel} waterLevel={waterLevel} />
                        )}
                      </>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          {coastForecast != null && (
            <div className="layout-container">
              <div className="coast-page__footer">
                <LastUpdated created={coastForecast.created} />
                <Text as="a" size="5" isLink={true} href={coastForecast.url} target="_blank" rel="noopener">
                  {translate('coastFooter/waveForecast')}
                </Text>
              </div>
            </div>
          )}
        </>

        {(subpageId === 'forecast' || subpageId === 'hourly-table') && (
          <div className="layout-container layout-container--with-padding">
            {isFirstRender === false && (
              <ComponentErrorBoundary>
                <div className="coast-page__observation-cards">
                  <ObservationsCards />
                </div>
              </ComponentErrorBoundary>
            )}

            {location.hasTide === true && (
              <div className="coast-page__card-list">
                <Heading level="h2" size="2" marginBottom={2}>
                  {translate('coastPage/tide')}
                </Heading>
                <CardList length={3} trackingId="coast-page__card-list">
                  <TideCard
                    tide={tide}
                    isError={tideIsError}
                    isInitialLoading={tideIsInitialLoading}
                    locationName={location.name}
                  ></TideCard>
                </CardList>
              </div>
            )}
          </div>
        )}

        {coastForecast != null && (
          <div className="layout-container layout-container--with-padding">
            <div className="coast-page__external-services">
              <ExternalServices
                items={[
                  {
                    text: translate('coastPage/externalServices/waveForecast/label'),
                    href: coastForecast.url
                  },
                  {
                    text: translate('coastPage/externalServices/iceCharts/label'),
                    href: translate('coastPage/externalServices/iceCharts/url')
                  }
                ]}
              />
            </div>
          </div>
        )}
      </div>
    );
  }

  function renderModalChildren() {
    if (location == null || subpageId == null) {
      return null;
    }

    const isHourly = subpageId === 'hourly-table';

    return (
      <>
        {isHourly && coastForecast != null && (
          <CoastForecastHourlyDialog
            location={location}
            hourlyIntervalsGroupedByDay={coastForecast.intervalsGroupedByDay}
            index={index}
            warnings={coastForecastWarnings[index]}
          />
        )}
      </>
    );
  }

  return <PageRoot renderChildren={renderChildren} renderModalChildren={renderModalChildren} />;
}

function getCurrentCoastTabFromSubpageId(subpageId: string, buttons: IGroupedLinkProps[]) {
  const viewIndex = buttons.findIndex(button => {
    return button.buttonId === subpageId;
  });

  if (subpageId === 'hourly-table') {
    return { id: 'forecast', index: 0 };
  }

  return { id: buttons[viewIndex].buttonId, index: viewIndex };
}

function getCoastButtons(localeCode: LocaleCode, translate: ITranslateFunction, location: ILocation) {
  const dailyTableUrl = createPageUrl({
    localeCode,
    pageId: 'coast',
    subpageId: 'forecast',
    locationId: location.id,
    urlPath: location.urlPath
  });

  const graphUrl = createPageUrl({
    localeCode,
    pageId: 'coast',
    subpageId: 'graph',
    locationId: location.id,
    urlPath: location.urlPath
  });

  const detailedTableUrl = createPageUrl({
    localeCode,
    pageId: 'coast',
    subpageId: 'table',
    locationId: location.id,
    urlPath: location.urlPath
  });

  // We use the same id as subpageId here, so we can search with subpageId in getCurrentCoastTabFromSubpageId
  const buttons: IGroupedLinkProps[] = [
    {
      buttonId: 'forecast',
      htmlFor: 'forecast',
      text: translate('coastPage/view/forecast'),
      href: dailyTableUrl,
      as: 'link'
    },
    {
      buttonId: 'graph',
      htmlFor: 'graph',
      text: translate('coastPage/view/graph'),
      href: graphUrl,
      as: 'link'
    },
    {
      buttonId: 'table',
      htmlFor: 'table',
      text: translate('coastPage/view/table'),
      href: detailedTableUrl,
      as: 'link'
    }
  ];

  return buttons;
}

function getFilteredWarningsGroupedByCoastForecastDayIntervals({
  coastForecast,
  warnings
}: {
  coastForecast?: ICoastForecast;
  warnings?: IWarning[];
}) {
  if (coastForecast == null || warnings == null) {
    return [];
  }

  // Filter warnings based on https://confluence.nrk.no/display/YRNO/Kystvarsel
  const filteredWarnings = filterWarningsByEventTypes(warnings, COAST_WEATHER_WARNINGS);

  return mapWarningsToIntervals(coastForecast.dayIntervals, filteredWarnings);
}

export default CoastPage;
