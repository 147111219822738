import classNames from 'classnames';
import { CloseButton } from '../CloseButton/CloseButton';
import { Heading } from '../Heading/Heading';
import { OverlayPanel } from '../OverlayPanel/OverlayPanel';
import { Text } from '../Text/Text';
import './InfoPanel.scss';

interface IProps {
  openedById: string;
  ariaLabel?: string;
  testId?: string;
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
  onClose: () => void;
  headerImage?: { src: string; width: number; height: number; alt: string };
  size?: 'medium' | 'large';
}

export function InfoPanel(props: IProps) {
  const { ariaLabel, testId, title, children, onClose, openedById, subtitle, headerImage, size = 'large' } = props;

  return (
    <OverlayPanel openedById={openedById} aria-label={ariaLabel} testId={testId} onClose={onClose}>
      <div
        className={classNames('info-panel', {
          'info-panel--medium': size === 'medium',
          'info-panel--large': size === 'large'
        })}
      >
        {headerImage && (
          <div
            className="info-panel__header-image-container"
            // Workaround to get the correct aspect ratio
            // Image is positioned absolute within this container
            style={{ paddingTop: `${(headerImage.height / headerImage.width) * 100}%` }}
            // Hidden for screen readers since having an image as first element in info panel feels confusing
            // The info text should be sufficient in this case
            aria-hidden={true}
          >
            <img
              className="info-panel__header-image"
              data-testid="info-panel-header-image"
              src={headerImage.src}
              width={headerImage.width}
              height={headerImage.height}
              alt={headerImage.alt}
            />
          </div>
        )}
        <div className="info-panel__header">
          <div className="info-panel__header-wrapper">
            <Heading
              level="h2"
              size="3"
              data-testid="info-panel-heading"
              // We set tabindex to -1 because we want core-dialog used by OverlayPanel
              // to automatically focus the heading and not the close button when the
              // info panel is opened.
              tabIndex={-1}
            >
              {title}
            </Heading>
            {subtitle && (
              <Text size="5" color="secondary">
                {subtitle}
              </Text>
            )}
          </div>

          <CloseButton as="button" className="info-panel__close-button" onClick={onClose} />
        </div>

        <div className="info-panel__body">{children}</div>
      </div>
    </OverlayPanel>
  );
}
