import { useMemo, useState } from 'react';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { ICoastForecastWithWaterLevel, IWaterLevel } from '../../model/coast';
import { getTemperatureCurveLegend } from '../../modelHelpers/legends';
import { GraphArrowsRow } from '../GraphArrowsRow/GraphArrowsRow';
import { GraphGap } from '../GraphGap/GraphGap';
import { IGraphLegendNew } from '../GraphLegendNew/GraphLegendNew';
import { GraphLegendRow } from '../GraphLegendRow/GraphLegendRow';
import { GraphShellNew, IGraphShellNewRow } from '../GraphShellNew/GraphShellNew';
import { GraphXAxisRow } from '../GraphXAxisRow/GraphXAxisRow';
import './CoastGraph.scss';
import { CoastGraph__HoverPanel } from './CoastGraph__HoverPanel';
import { CoastGraph__SeaCurrent } from './CoastGraph__SeaCurrent';
import { CoastGraph__Temperature } from './CoastGraph__Temperature';
import { CoastGraph__WaterLevel } from './CoastGraph__WaterLevel';
import { CoastGraph__WaveHeight } from './CoastGraph__WaveHeight';
import { CoastGraph__Wind } from './CoastGraph__Wind';
import { createCoastGraphData, createWaterLevelGraphData } from './helpers/data';

const MIN_WIDTH = 1000;
const ROW_HEIGHT = 20;
const GAP_HEIGHT = 24;

interface IProps {
  coastForecast: ICoastForecastWithWaterLevel;
  waterLevel?: IWaterLevel;
}

export type TCoastGraphHoverType = 'waveHeight' | 'temperature' | 'seaCurrent' | 'wind' | 'waterLevel';

interface ICoastGraphHover {
  type: TCoastGraphHoverType;
  index: number;
  x: number;
  y: number;
}

export type TCoastGraphOnHover = (hover: ICoastGraphHover) => void;

export const CoastGraph = (props: IProps) => {
  const { coastForecast, waterLevel } = props;

  const translate = useTranslate();
  const {
    start,
    end,
    dayHeaderLabels,
    verticalLines,
    temperatureGraphCoordinates,
    temperatureGraphMetrics,
    waveHeightGraphMetrics,
    waveHeightGraphCoordinates,
    waveHeightArrows,
    seaCurrentGraphMetrics,
    seaCurrentGraphCoordinates,
    seaCurrentArrows,
    windArrows,
    windGraphMetrics,
    windGraphCoordinates
  } = useMemo(() => {
    return createCoastGraphData({
      coastForecast,
      translate
    });
  }, [coastForecast, translate]);

  const waterLevelGraphData = useMemo(() => {
    return createWaterLevelGraphData({
      start,
      end,
      waterLevel
    });
  }, [start, end, waterLevel]);

  const [hover, setHover] = useState<ICoastGraphHover | undefined>(undefined);

  function onHover(hover: ICoastGraphHover) {
    setHover(hover);
  }

  function onHoverCancel() {
    setHover(undefined);
  }

  const temperatureGraphHeight = temperatureGraphMetrics.graphDimensions.rows * ROW_HEIGHT;
  const waveHeightGraphHeight = waveHeightGraphMetrics.graphDimensions.rows * ROW_HEIGHT;
  const windGraphHeight = windGraphMetrics.graphDimensions.rows * ROW_HEIGHT;
  const seaCurrentGraphHeight = seaCurrentGraphMetrics.graphDimensions.rows * ROW_HEIGHT;

  const { normalizedZeroY } = temperatureGraphMetrics;

  const temperatureGraphLegends = getTemperatureGraphLegends(normalizedZeroY);
  const windGraphLegends: IGraphLegendNew[] = [{ type: 'wind-curve' }, { type: 'wind-gust-curve' }];
  const waveHeightGraphLegends: IGraphLegendNew[] = [{ type: 'wave-height-curve' }];
  const seaCurrentGraphLegends: IGraphLegendNew[] = [{ type: 'sea-current-curve' }];

  const rows: IGraphShellNewRow[] = [
    {
      content: (
        <GraphXAxisRow
          type="columns"
          columns={dayHeaderLabels}
          align="left"
          bold={true}
          hideNarrowColumns={true}
          ellipsis={true}
          color="primary"
        />
      )
    },
    {
      yAxisLeft: windGraphMetrics.yAxisTicks,
      content: (
        <CoastGraph__Wind
          height={windGraphHeight}
          verticalLines={verticalLines}
          currentHoverIndex={hover != null && hover.type === 'wind' ? hover.index : undefined}
          onHover={onHover}
          onHoverCancel={onHoverCancel}
          windGraphCoordinates={windGraphCoordinates}
          windGraphMetrics={windGraphMetrics}
        />
      )
    },
    {
      content: <GraphArrowsRow type="columns" columns={windArrows} />
    },
    {
      content: <GraphLegendRow legends={windGraphLegends} />
    },
    {
      content: <GraphGap height={GAP_HEIGHT} />
    },
    {
      hideOnDesktop: true,
      content: (
        <GraphXAxisRow
          type="columns"
          columns={dayHeaderLabels}
          align="left"
          bold={true}
          hideNarrowColumns={true}
          ellipsis={true}
        />
      )
    },
    {
      yAxisLeft: waveHeightGraphMetrics.yAxisTicks,
      content: (
        <CoastGraph__WaveHeight
          height={waveHeightGraphHeight}
          verticalLines={verticalLines}
          currentHoverIndex={hover != null && hover.type === 'waveHeight' ? hover.index : undefined}
          onHover={onHover}
          onHoverCancel={onHoverCancel}
          waveHeightGraphCoordinates={waveHeightGraphCoordinates}
          waveHeightGraphMetrics={waveHeightGraphMetrics}
        />
      )
    },
    {
      content: <GraphArrowsRow type="columns" arrowType="water" columns={waveHeightArrows} />
    },
    {
      content: <GraphLegendRow legends={waveHeightGraphLegends} />
    },
    {
      content: <GraphGap height={GAP_HEIGHT} />
    },
    {
      hideOnDesktop: true,
      content: (
        <GraphXAxisRow
          type="columns"
          columns={dayHeaderLabels}
          align="left"
          bold={true}
          hideNarrowColumns={true}
          ellipsis={true}
        />
      )
    },
    {
      yAxisLeft: seaCurrentGraphMetrics.yAxisTicks,
      content: (
        <CoastGraph__SeaCurrent
          height={seaCurrentGraphHeight}
          verticalLines={verticalLines}
          currentHoverIndex={hover != null && hover.type === 'seaCurrent' ? hover.index : undefined}
          onHover={onHover}
          onHoverCancel={onHoverCancel}
          seaCurrentGraphCoordinates={seaCurrentGraphCoordinates}
          seaCurrentGraphMetrics={seaCurrentGraphMetrics}
        />
      )
    },
    {
      content: <GraphArrowsRow type="columns" arrowType="water" columns={seaCurrentArrows} />
    },
    { content: <GraphLegendRow legends={seaCurrentGraphLegends} /> },
    {
      content: <GraphGap height={GAP_HEIGHT} />
    },
    {
      hideOnDesktop: true,
      content: (
        <GraphXAxisRow
          type="columns"
          columns={dayHeaderLabels}
          align="left"
          bold={true}
          hideNarrowColumns={true}
          ellipsis={true}
        />
      )
    },
    {
      yAxisLeft: temperatureGraphMetrics.yAxisTicks,
      content: (
        <CoastGraph__Temperature
          height={temperatureGraphHeight}
          verticalLines={verticalLines}
          currentHoverIndex={hover != null && hover.type === 'temperature' ? hover.index : undefined}
          onHover={onHover}
          onHoverCancel={onHoverCancel}
          temperatureGraphCoordinates={temperatureGraphCoordinates}
          temperatureGraphMetrics={temperatureGraphMetrics}
        />
      )
    },
    { content: <GraphLegendRow legends={temperatureGraphLegends} /> }
  ];

  if (waterLevelGraphData != null) {
    const { waterLevelGraphMetrics, waterLevelGraphCoordinates } = waterLevelGraphData;
    const waterLevelGraphHeight = waterLevelGraphMetrics.graphDimensions.rows * ROW_HEIGHT;
    const waterLevelGraphLegends: IGraphLegendNew[] = [
      { type: 'water-level-prediction' },
      { type: 'water-level-forecast' }
    ];

    rows.push(
      ...[
        {
          content: <GraphGap height={GAP_HEIGHT} />
        },
        {
          hideOnDesktop: true,
          content: (
            <GraphXAxisRow
              type="columns"
              columns={dayHeaderLabels}
              align="left"
              bold={true}
              hideNarrowColumns={true}
              ellipsis={true}
            />
          )
        },
        {
          yAxisLeft: waterLevelGraphMetrics.yAxisTicks,
          content: (
            <CoastGraph__WaterLevel
              height={waterLevelGraphHeight}
              verticalLines={verticalLines}
              currentHoverIndex={hover != null && hover.type === 'waterLevel' ? hover.index : undefined}
              onHover={onHover}
              onHoverCancel={onHoverCancel}
              waterLevelGraphCoordinates={waterLevelGraphCoordinates}
              waterLevelGraphMetrics={waterLevelGraphMetrics}
            />
          )
        },
        { content: <GraphLegendRow legends={waterLevelGraphLegends} /> }
      ]
    );
  }

  return (
    <div className="coast-graph">
      <GraphShellNew
        minWidth={MIN_WIDTH}
        ariaLabel={translate('detailedGraph/title', { numberOfDays: coastForecast.dayIntervals.length })}
        rows={rows}
        withPadding={true}
      />

      {hover != null && (
        <CoastGraph__HoverPanel
          type={hover.type}
          x={hover.x}
          y={hover.y}
          longInterval={coastForecast.longIntervals[hover.index]}
        />
      )}
    </div>
  );
};

const getTemperatureGraphLegends = (normalizedZeroY: number) => {
  const temperatureGraphLegends: IGraphLegendNew[] = [{ type: getTemperatureCurveLegend(normalizedZeroY) }];

  temperatureGraphLegends.push({ type: 'water-temperature-curve' });

  return temperatureGraphLegends;
};
